import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    container: {
        textAlign:'center',
        paddingTop: '10px'
    },
    constitution: {
        height: '600px',
        width: '100%',
  },
}));

function Constitution() {
    const classes = useStyles();
    return (
        <Container className={classes.container}>
            <iframe className={classes.constitution} title="Club Constitution" src="https://docs.google.com/document/d/1wRVaNqbjmF-eyK1ARAP-PVA1WV0JtvKuLSai-WmbglU/pub?embedded=true"></iframe>
        </Container>
    );

}

export default Constitution;
